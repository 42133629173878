import { forwardRef, useMemo, type ReactNode, type RefObject } from "react";
import DateInput from "src/modules/common/components/atoms/inputs/DateInput";
import { Input } from "src/modules/common/components/atoms/inputs/Input";
import { InputAddress } from "src/modules/common/components/atoms/inputs/InputAddress";
import { InputButton } from "src/modules/common/components/atoms/inputs/InputButton";
import { SelectInput } from "src/modules/common/components/atoms/inputs/SelectInput";
import { Textarea } from "src/modules/common/components/atoms/inputs/Textarea";
import { schemaAddress, schemaDate } from "src/modules/onboarding/schemas";
import {
  formatLocaleCurrencyToNumber,
  formatNumberToLocaleCurrency,
} from "src/modules/onboarding/utils/numberUtils";
import { z } from "zod";
import InputSiret from "~/modules/onboarding/components/atoms/InputSiret";
import {
  type Address,
  type SimpleAnswerValue,
  type SiretInputValue,
} from "../../../onboarding/interfaces";
import { type ResponseFieldType } from "../../interfaces";
import { schemaRadio, schemaSelect } from "../../schemas";
import { Text } from "../atoms/Text";
import RadioInput from "../atoms/inputs/RadioInput";
import { cn } from "../utils/styles";
import PercentageResponseField from "./PercentageResponseField";

export type InputFieldValue = string | number | Date | Address | undefined;
export type DateInputSize = "xs" | "small" | "medium";
export type ResponseFieldValue =
  | string
  | Address
  | number
  | Date
  | SiretInputValue;

export interface ResponseFieldProps {
  className?: string;
  name?: string;
  value?: ResponseFieldValue;
  placeholder?: string;
  type?: ResponseFieldType;
  label?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onChange?: (value: SimpleAnswerValue | undefined) => void;
  unit?: string;
  // TODO REFACTO this is only used when type="select"|"btn", handle this with Typescript
  options?: {
    label: string;
    value: string;
    icon?: ReactNode;
  }[];
  countryList?: string[];
  popoverClassName?: string;
  unitClassName?: string;
  labelClassName?: string;
  containerClassName?: string;
  dateInputSize?: DateInputSize;
  isHiddenMode?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

const ResponseField = forwardRef<
  HTMLTextAreaElement | HTMLInputElement,
  ResponseFieldProps
>(
  (
    {
      className,
      name,
      value,
      placeholder,
      type = "text",
      label,
      prefix,
      suffix,
      onChange,
      unit,
      options,
      countryList,
      popoverClassName,
      unitClassName,
      labelClassName,
      containerClassName,
      dateInputSize,
      isHiddenMode = false,
      disabled = false,
      maxLength,
    },
    inputRef,
  ) => {
    const input = useMemo(() => {
      switch (type) {
        case "btn":
        case "btn-wrap":
          return options?.map((option) => (
            <InputButton
              key={option.value}
              onClick={() => onChange && onChange(option.value)}
              checked={value === option.value}
              className={className}
              disabled={disabled}
            >
              <div className="flex items-center gap-2.5">
                {option.icon} {option.label}
              </div>
            </InputButton>
          ));
        case "textarea":
          return (
            <Textarea
              ref={inputRef as RefObject<HTMLTextAreaElement>}
              placeholder={placeholder}
              name={name}
              key={name}
              value={value?.toString() ?? ""}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target.value);
                }
              }}
              className={className}
              disabled={disabled}
              maxLength={maxLength}
            />
          );
        case "address":
          return (
            <InputAddress
              setInputValue={(value) => {
                if (onChange) {
                  onChange(value);
                }
              }}
              className={className}
              initialValue={schemaAddress.optional().parse(value) ?? undefined}
              countryList={countryList}
              disabled={disabled}
            />
          );
        case "text":
          return (
            <Input
              ref={inputRef as RefObject<HTMLInputElement>}
              placeholder={placeholder}
              type={type}
              name={name}
              key={name}
              value={value?.toString() ?? ""}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target.value);
                }
              }}
              unit={unit}
              className={className}
              isHiddenMode={isHiddenMode}
              disabled={disabled}
            />
          );
        case "number":
          return (
            <Input
              ref={inputRef as RefObject<HTMLInputElement>}
              placeholder={placeholder}
              type="text"
              inputMode="numeric"
              name={name}
              key={name}
              value={value?.toString() ?? ""}
              onChange={(e) => {
                // Regex on non-numeric and non-dot characters
                const nonNumericRegex = /[^0-9.]/g;
                if (onChange) {
                  let changedValue = e.target.value;

                  if (changedValue.includes(",")) {
                    changedValue = changedValue.replace(",", ".");
                  }

                  // Remove any non-numeric or non-dot characters
                  changedValue = changedValue.replace(nonNumericRegex, "");

                  onChange(changedValue);
                }
              }}
              unit={unit}
              className={className}
              unitClassName={unitClassName}
              disabled={disabled}
            />
          );
        case "percentage":
          return (
            <PercentageResponseField
              ref={inputRef as RefObject<HTMLInputElement>}
              placeholder={placeholder}
              name={name}
              key={name}
              className={className}
              value={value}
              onChange={onChange}
              unitClassName={unitClassName}
              disabled={disabled}
            />
          );
        case "currency-positive":
        case "currency":
          return (
            <Input
              ref={inputRef as RefObject<HTMLInputElement>}
              placeholder={placeholder}
              type={"text"}
              name={name}
              key={name}
              value={
                z
                  .string()
                  .optional()
                  .parse(
                    typeof value === "number"
                      ? formatNumberToLocaleCurrency(value)
                      : value?.toString(),
                  ) ?? ""
              }
              onChange={(e) => {
                if (onChange) {
                  // Try to format the value to a locale currency,
                  // if it's a number update the value, if not do nothing
                  const formattedValue = z.coerce
                    .number()
                    .safeParse(formatLocaleCurrencyToNumber(e.target.value));

                  if (formattedValue.success) {
                    onChange(formatNumberToLocaleCurrency(formattedValue.data));
                  }
                }
              }}
              unit={unit}
              className={cn("!pr-8", className)}
              unitClassName={unitClassName}
              disabled={disabled}
            />
          );
        case "select":
          return (
            <SelectInput
              ref={inputRef as RefObject<HTMLSelectElement>}
              options={options}
              onChange={(value) => {
                onChange && onChange(value);
              }}
              value={schemaSelect.optional().parse(value) ?? ""}
              className={className}
              disabled={disabled}
            />
          );
        case "date":
          return (
            <DateInput
              value={schemaDate.optional().parse(value) ?? undefined}
              onChange={(date: string) => {
                const todayDate = new Date().toISOString();
                const isAFuturDate = date > todayDate;

                if (!isAFuturDate) {
                  return;
                }
                onChange && onChange(date);
              }}
              className={className}
              popoverClassName={popoverClassName}
              size={dateInputSize}
              disabled={disabled}
            />
          );
        case "siret":
          return (
            <InputSiret
              setInputValue={(value) => {
                if (onChange) {
                  onChange(value);
                }
              }}
              className={className}
              disabled={disabled}
            />
          );
        case "radio":
          return (
            <RadioInput
              ref={inputRef as RefObject<HTMLDivElement>}
              options={options}
              onChange={(value) => {
                onChange && onChange(value);
              }}
              value={schemaRadio.optional().parse(value) ?? ""}
              className={className}
              disabled={disabled}
            />
          );
      }
    }, [
      type,
      options,
      inputRef,
      placeholder,
      name,
      value,
      className,
      disabled,
      maxLength,
      countryList,
      unit,
      isHiddenMode,
      unitClassName,
      onChange,
      popoverClassName,
      dateInputSize,
    ]);

    return (
      <div className={cn("flex flex-col gap-[2px]", containerClassName)}>
        {!!label && (
          <Text size="bodyS" className={cn("ml-3", labelClassName)}>
            {label}
          </Text>
        )}
        {!!prefix && prefix}

        {input}

        {!!suffix && suffix}
      </div>
    );
  },
);

ResponseField.displayName = "ResponseField";

export { ResponseField };
