import { forwardRef, useState } from "react";
import { Input } from "src/modules/common/components/atoms/inputs/Input";
import { z } from "zod";
import { type ResponseFieldProps } from "./ResponseField";

const PercentageResponseField = forwardRef<
  HTMLInputElement,
  ResponseFieldProps
>(({ placeholder, name, value, onChange, className, unitClassName }, ref) => {
  const parsedNumberValue = z.coerce.number().safeParse(value);
  const initialStringValueInPercent = parsedNumberValue.success
    ? (parsedNumberValue.data * 100).toFixed(2)
    : "";

  const [fieldValue, setFieldValue] = useState<string>(
    initialStringValueInPercent,
  );

  return (
    <Input
      ref={ref}
      placeholder={placeholder}
      type="text"
      inputMode="numeric"
      name={name}
      key={name}
      value={fieldValue}
      unit="%"
      onChange={(e) => {
        let changedValue = e.target.value;

        if (changedValue.includes(",")) {
          changedValue = changedValue.replace(",", ".");
        }

        setFieldValue(changedValue);

        if (onChange) {
          const parsedValue = z.coerce.number().safeParse(changedValue);

          if (parsedValue.success) {
            const valueInDecimal = parsedValue.data / 100;

            onChange(valueInDecimal);
          } else {
            setFieldValue(initialStringValueInPercent);
            onChange("");
          }
        }
      }}
      className={className}
      unitClassName={unitClassName}
    />
  );
});

PercentageResponseField.displayName = "PercentageResponseField";

export default PercentageResponseField;
