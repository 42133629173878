import { type ElementRef, forwardRef, useEffect } from "react";
import { cn } from "../../utils/styles";
import { Label } from "../form/label";
import {
  RadioGroup,
  type RadioGroupInput,
  RadioGroupItem,
} from "../RadioGroupShadcn";

export type RadioOption = { label: string; value: string };

interface RadioInputProps {
  onChange: (value: string) => void;
  value?: string;
  options?: RadioOption[];
  className?: string;
  disabled?: boolean;
}

const RadioInput = forwardRef<ElementRef<RadioGroupInput>, RadioInputProps>(
  (
    { options = [], onChange, value = "", className, disabled = false },
    ref,
  ) => {
    // Set first value as default
    useEffect(() => {
      if (value === "" && options[0]) {
        onChange(options[0].value);
      }
    }, [onChange, options, value]);

    return (
      <RadioGroup
        ref={ref}
        value={value}
        onValueChange={(value) => onChange(value)}
        className={className}
        disabled={disabled}
      >
        {options?.map((option) => {
          const inputId = `radio-${option.value}-${Math.random()}`;

          return (
            <div className="flex items-center gap-[6px]" key={option.value}>
              <RadioGroupItem value={option.value} id={inputId} />
              <Label
                htmlFor={inputId}
                className={cn(
                  "cursor-pointer",
                  disabled && "cursor-not-allowed opacity-50",
                )}
              >
                {option.label}
              </Label>
            </div>
          );
        })}
      </RadioGroup>
    );
  },
);

RadioInput.displayName = "RadioInput";

export default RadioInput;
