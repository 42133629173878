"use client";
import * as React from "react";
import { textSizeClasses } from "src/modules/common/components/atoms/Text";
import { cn } from "~/modules/common/components/utils/styles";

export type TextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    inputLengthLimit?: number;
  };

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, maxLength, ...props }, ref) => {
    const [characterCount, setCharacterCount] = React.useState(0);

    const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
      setCharacterCount(event.currentTarget.value.length);
    };

    return (
      <div className="relative">
        <textarea
          className={cn(
            "ring-offset-white flex min-h-[140px] w-full rounded-lg border border-new-dark-4 bg-new-light-4 p-3 text-new-dark-0 placeholder:text-new-dark-3 focus-visible:border-new-primary-1 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            textSizeClasses.bodyRegular,
            className,
          )}
          maxLength={maxLength}
          ref={ref}
          onInput={handleInput}
          {...props}
        />
        {maxLength && (
          <div className="absolute bottom-2 right-3 text-xs text-new-dark-3">
            ({characterCount}/{maxLength} caractères)
          </div>
        )}
      </div>
    );
  },
);

Textarea.displayName = "Textarea";

export { Textarea };
